

@tailwind base;
@tailwind components;
@tailwind utilities;

.custom-card-image .card.image-full:before {
    border-radius: 0.5rem;
    opacity:0.45;
}

.map-container {
    height: 50vh;
    width:50vw;
    }
     
.sidebar {
background-color: rgba(35, 55, 75, 0.9);
color: #fff;
padding: 6px 12px;
font-family: monospace;
z-index: 1;
position: absolute;
top: 0;
left: 0;
margin: 12px;
border-radius: 4px;
}
.hero {
    background: url('../public/night_re1.jpg') no-repeat center center/cover;
    height:100vh;
    position: relative;
    color: #fff;

}